<template>
  <div class="token-item" @click="goTokenInfo">
    <div class="network">{{ token.blockchain_title }}</div>
    <div class="token-info">
      <div class="token-info-left">
        <div class="token-logo-wrap">
          <img class="symbol-logo" :src="token.icon_url" alt="" />
          <img class="network-logo" :src="token.blockchain_icon" alt="" />
        </div>
        <div class="token-detail">
          <div class="symbol">{{ token.symbol }}</div>
          <div class="address address-pc">
            {{ token.address }}
          </div>
          <div class="address address-mobile">
            <span v-if="token.address.length > 24">
              {{ token.address.slice(0, 12) + '...' + token.address.slice(-12) }}
            </span>
            <span v-else>{{ token.address }}</span>
          </div>
        </div>
      </div>
      <div class="token-info-right" v-if="token.isSelected">
        <img :src="selectedImg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import darkModeImg from '../../mixins/darkModeImg';
export default {
  props: {
    token: {
      type: Object,
    },
  },
  mixins: [darkModeImg],
  computed: {
    selectedImg() {
      return require(`../../assets/home/selected${this.darkModeImg}.png`);
    },
  },
  methods: {
    goTokenInfo() {
      // console.log('path', this.$route.path);
      if (this.$route.path === '/token-info') {
        this.$emit('getToken', this.token);
      } else {
        this.$router.push({
          // name: 'TokenInfo',
          path: '/token-info',
          query: { id: this.token.hid },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.token-item {
  &:nth-child(n + 2) {
    margin-top: 0.2rem;
  }
  .network {
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC-Medium;
    color: var(--gray-15);
    padding: 0 0.06rem;
  }
  .token-info {
    margin-top: 0.13rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.08rem 0.06rem;
    cursor: pointer;
    .token-info-left {
      display: flex;
      align-items: center;
      .token-logo-wrap {
        position: relative;
        display: flex;
        .symbol-logo {
          width: 0.32rem;
          height: 0.32rem;
          border-radius: 50%;
        }
        .network-logo {
          position: absolute;
          right: -0.02rem;
          bottom: -0.02rem;
          border: 0.02rem solid var(--search-result-bg);
          width: 0.12rem;
          height: 0.12rem;
          border-radius: 50%;
          box-sizing: content-box;
        }
      }
      .token-detail {
        margin-left: 0.12rem;
        .symbol {
          font-size: 0.13rem;
          font-family: PingFang SC, PingFang SC-Medium;
          color: var(--gray-15);
        }
        .address {
          margin-top: 0.02rem;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC-Medium;
          color: var(--gray-11);
          transform: scale(0.83);
          transform-origin: left center;
        }
        .address-pc {
          // margin-left: -0.25rem;
          display: block;
        }
        .address-mobile {
          display: none;
        }
      }
    }
    .token-info-right {
      display: flex;
      img {
        width: 0.16rem;
        height: 0.16rem;
      }
    }
  }
  .token-info:hover {
    background: var(--search-result-token-info-hover);
    border-radius: 0.12rem;
  }
}
@media screen and (max-width: 767px) {
  .token-item {
    &:nth-child(n + 2) {
      margin-top: 0.58rem;
    }
    .network {
      font-size: 0.26rem;
    }
    .token-info {
      padding: 0;
      margin-top: 0.36rem;
      .token-info-left {
        .token-logo-wrap {
          .symbol-logo {
            width: 0.72rem;
            height: 0.72rem;
          }
          .network-logo {
            width: 0.28rem;
            height: 0.28rem;
            border: 0.04rem solid var(--search-result-bg);
          }
        }
        .token-detail {
          margin-left: 0.28rem;
          .symbol {
            font-size: 0.26rem;
          }
          .address {
            font-size: 0.2rem;
          }
          .address-pc {
            display: none;
          }
          .address-mobile {
            display: block;
            // margin-left: -0.05rem;
          }
        }
      }
      .token-info-right {
        img {
          width: 0.4rem;
          height: 0.4rem;
        }
      }
    }
  }
}
</style>
