import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n/index'
import router from './router'
import store from './store'
import { setMode } from "./utils";
import './style/index.scss'
// import './style/dark.scss'

import tp from 'tp-js-sdk'


import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// 不同模式样式
tp.isDarkMode().then((res) => {
  if (res.result && res.data) {
    setMode(true)
    store.commit('setDark', res.data)
  } else {
    setMode(false)
  }
})

// // 当不是 dapp 浏览器的时候
// if (navigator.userAgent.indexOf('TokenPocket') === -1) {
//   // 获取系统主题
//   const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)') // 是深色
//   if (isDarkTheme.matches) {
//     // 是深色 主题设置为深色。
//     store.commit('setDark', true)
//   } else {
//     // 不是深色 主题设置为浅色。
//     store.commit('setDark', false)
//   }
// }

Vue.config.productionTip = false

// console.log('location', location)

// ios 10 禁止缩放
// ios 10以上
window.onload = function () {
  document.documentElement.addEventListener(
    'touchstart',
    function (event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    },
    false
  )
  var lastTouchEnd = 0
  document.addEventListener(
    'touchend',
    function (event) {
      var now = Date.now()
      if (now - lastTouchEnd <= 300) {
        event.preventDefault()
      }
      lastTouchEnd = now
    },
    false
  )
  // 阻止双指放大
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  // 预渲染
  // mounted() {
  //   document.dispatchEvent(new Event('custom-render-trigger'))
  // },
}).$mount('#app')
