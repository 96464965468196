<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: { ...mapGetters(['isDark']) },
  mounted() {
    this.$store.dispatch('SET_BLOCK_CHAIN_LIST', { lang: 'zh-Hans', os: 'web' });
    this.$store.dispatch('SET_BLOCK_CHAIN_LIST', { lang: 'en', os: 'web' });
    this.$store.commit(
      'setMobileSize',
      document.documentElement.clientWidth <= 767
    );
    // window.onresize = () => {
    //   console.log('width', document.documentElement.clientWidth);
    //   this.$store.commit(
    //     'setMobileSize',
    //     document.documentElement.clientWidth <= 767
    //   );
    // };
  },
};
</script>
<style lang="scss">
// #app {
//   background: var(--bg-1);
// }
</style>
