export default {
  submit: '确定',
  loading: '加载中...',
  tokenInfoTitle: '代币信息',
  tokenDetectionTitle: '安全检测',
  baseInfo: '基本信息',
  projectSymbol: '项目代码',
  projectName: '项目名称',
  contractAddress: '合约地址',
  contractOwner: '合约owner',
  website: '官方网站',
  issuer: 'issuer',
  issueTime: 'Issue Time',
  socialInfo: '社交信息',
  more: '更多',
  releaseInfo: '发行信息',
  releaseTotal: '发行总量',
  releaseTime: '发行时间',
  securityInfo: '安全信息',
  moreSecurityInfo: '前往查看更多安全信息',
  detection: '检测',
  network: '选择网络',
  detectionDesc:
    '安全检测结果基于链上数据分析，仅供参考。这并不意味着数据是 100% 正确的。请始终进行自己的研究并承担相应的风险。TokenPocket 将致力于为用户提供更多有用的数据。',
  securityOverview: '安全概括',
  risky: '危险警告',
  risky_2: '风险',
  attentionTip: '安全提示',
  contractCreator: '合约创造者',
  circulation: 'Circulation',
  tradingSecurityReport: '交易安全报告',
  buy_tax: '买入税率',
  sell_tax: '卖出税率',
  unknown_buy_tax: '买入税率未知',
  unknown_sell_tax: '卖出税率未知',
  contractSecurityReport: '合约安全报告',

  trade_0_text1: '这看上去不像是貔貅',
  trade_0_text2: '交易税率不可修改',
  trade_0_text3: '交易不能暂停',
  trade_0_text4: '没有黑名单功能',
  trade_0_text5: '没有白名单功能',
  trade_0_text6: '没有防巨鲸功能',
  trade_0_text7: '可以买入',
  trade_0_text8: '可以全部卖出',
  trade_0_text9: '没有交易冷却',
  trade_0_text10: '没有针对个人设置滑点',
  trade_0_text11: '没有修改最大卖出或转移',

  trade_1_text1: '这是一个貔貅',
  trade_1_text2: '交易税率可修改',
  trade_1_text3: '交易可以暂停',
  trade_1_text4: '有黑名单功能',
  trade_1_text5: '有白名单功能',
  trade_1_text6: '有防巨鲸功能',
  trade_1_text7: '无法买入',
  trade_1_text8: '不可以全部卖出',
  trade_1_text9: '有交易冷却',
  trade_1_text10: '有针对个人设置滑点',
  trade_1_text11: '有修改最大卖出或转移',

  trade_2_text1: '权限已转移，无法设置暂停交易',
  trade_2_text2: '权限已转移，无法设置交易税',
  trade_2_text3: '权限已转移，无法设置交易冷却',
  trade_2_text4: '权限已转移，无法更新最大卖出',

  trade_token_status_text1: '该代币被标记为恶意或钓鱼代币，切勿交互',

  airdropScam: '这是一个空投诈骗',
  fakeToken: '这是一个假币',
  contract_0_text1: '合约代码已开源',
  contract_0_text2: '没有代理合约',
  contract_0_text3: '没有增发的功能',
  contract_0_text4: '拥有者不能修改余额',
  contract_0_text5: '没有隐藏的拥有者',
  contract_0_text6: '没有取回所有权',
  contract_0_text7: '不可自毁合约',
  contract_0_text8: '不存在外部方法',

  contract_1_text1: '合约代码没有开源',
  contract_1_text2: '有代理合约',
  contract_1_text3: '有增发的功能',
  contract_1_text4: '拥有者可以修改余额',
  contract_1_text5: '有隐藏的拥有者',
  contract_1_text6: '有取回所有权',
  contract_1_text7: '可自毁合约，资产会归零',
  contract_1_text8: '存在外部方法',

  contract_2_text1: '权限已转移，无法进行增发',
  contract_2_text2: '权限已转移，无法修改余额',

  copySuccess:
    '已复制。请注意，您复制的地址属于合约地址，请不要往该地址转账，否则无法找回资产！',
  report: '举报',
  placeholder: '请输入代币合约地址',

  errorContract: '请求的数据有误，请确认合约是否正确',
  errorData: '没有请求到数据，请您稍后重试',
  understood: '好的',
  copySuccess2: '复制成功',
  copySuccess3: '链接复制成功',

  is_honeypot: '貔貅就是无法进行卖出的代币，这种代币没有价值',
  locked: '锁仓',
  smartContract: '合约账号',

  liquidityPool: '流动性池子',
  poolInfo: '池子信息',
  lockedPercent: '锁仓比例',
  amm: 'AMM',
  pair: '池子对',
  amount: '数量',
  liquidity: '流动性',

  share_report: '分享',
  check_report: 'Check Report',
  contract_ownership: '开发者已放弃合约所有权',

  giveUpOwnership: '(合约所有权已放弃)',

  security: '安全',
  price: '价格',
  unconfirmed: '未确认',
  no_data: '查无此代币～',
  base_info: {
    title: '基本信息',
    contract: '合约地址',
    issuer: '发行地址',
    issue_Time: '发行时间',
    links: '相关链接',
    inscriptionID: 'InscriptionID',
    admin: '管理员',
    network: '网络',
    mintTime: '铸造时间',
  },
  overview: {
    title: '概览',
    max_supply: '最大供应量',
    total_minted: '总供应量',
    holders: '持有者',
    circulating_supply: '流通供应量',
    total_market_cap: '总市值',
    circulating_market_cap: '流动市值',
    total_transactions: '总交易次数',
    status: '状态',
    finished: '已结束',
    mint: 'Mint',
  },
  holder_info: {
    title_1: '持有者',
    title_2: '持有地址',
    title_3: '总持有地址数:',
    title_4: '主要持有地址',
    text_1: '持仓',
    text_2: '占比',
    text_3: '地址',
    text_4: '其它',
  },
  trading_info: {
    title_1: '交易信息',
    title_2: '池子',
    title_3: '链上交易数据',
    text_1: '交易量',
    text_2: '交易地址数',
    text_3: '交易次数',
    text_4: '买',
    text_5: '卖',
    text_6: '前往交易',
    text_7: 'DEX',
    text_8: '池子对',
    text_9: '流动性',
    text_10: '最大',
    noData_1: '暂未收录交易信息',
  },
  brc20_trading_info: {
    text_1: '操作',
    text_2: '数量',
    text_3: '来自',
    text_4: '到',
    text_5: '时间',
    text_6: '失败',
  },
  rune: {
    base_title: '基本信息',
    mint_title: '铸造信息',
    not_started: '未开始',
    in_progress: '进行中',
    over: '已结束',
    unlimited: '无限',
    base: {
      text_1: '总供应量',
      text_2: '预挖量',
      text_3: '已燃烧量',
      text_4: 'id',
      text_5: '符文精度',
      text_6: '蚀刻交易',
    },
    mint: {
      text_1: '铸造状态',
      text_2: '蚀刻区块',
      text_3: '当前区块',
      text_4: '开始铸造区块',
      text_5: '结束铸造区块',
      text_6: '铸造总次数',
      text_7: '已铸造次数',
      text_8: '剩余铸造次数',
      text_9: '每次铸造数量',
    }
  },
  tools: {
    title: '热门工具',
    tool_1: '代币检测',
    tool_2: '授权检测',
    tool_3: '批量转账',
    tool_4: 'App验证',
    tool_5: '铭文助手',
    tool_6: 'Nostr批量转账',
  },
  root_data: {
    tab_1: '交易信息',
    tab_2: '团队与融资',
    tab_3: '团队信息',
    investors: '投资人',
    checkAll: '展开全部',
    financingAmount: '融资额',
    noData: '暂无信息',
    noData_1: '暂未收录团队与融资信息',
  },
};
