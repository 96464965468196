<template>
  <div class="search-input" :class="{ 'shadow': $route.path === '/token-info' }">
    <img class="search-icon" :src="searchImg" alt="" />
    <div class="input-wrap">
      <input
        type="text"
        :placeholder="$t('placeholder')"
        v-model="params.inputVal"
        @input="inputChange"
        @keydown.enter="onEnter"
      />
      <img
        v-if="params.inputVal"
        class="delete"
        :src="deleteImg"
        @click="onDelete"
        alt=""
      />
    </div>
    <!-- @focus="onFocus" -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { tokenSearch } from '../../utils';
import darkModeImg from '../../mixins/darkModeImg';
import _ from 'lodash';
export default {
  mixins: [darkModeImg],
  data() {
    return {
      params: {
        inputVal: '',
        resultList: [],
        searchStatus: '',
      },
    };
  },
  computed: {
    ...mapGetters(['isDark']),
    searchImg() {
      return require(`../../assets/search${this.darkModeImg}.png`);
    },
    deleteImg() {
      return require(`../../assets/delete${this.darkModeImg}.png`);
    },
  },
  methods: {
    onDelete() {
      // console.log('onDelete');
      this.params.inputVal = '';
      this.params.searchStatus = '';
      this.params.resultList = [];
      this.$emit('getSearchParams', this.params);
    },
    onEnter() {
      this.params.resultList = [];
      this.params.searchStatus = 'loading';
      this.$emit('getSearchParams', this.params);
      this.onSearch();
    },
    async onSearch() {
      let lang = 'en';
      if (this.$i18n.locale === 'zh') {
        lang = 'zh-Hans';
      }
      const data = await tokenSearch(this.params.inputVal, lang);
      // console.log('data', data);
      // this.resultList = [...data, ...data,...data,...data,...data,];
      this.params.resultList = data;
      // this.$emit('getSearchParams', this.params);
      if (data && data.length) {
        this.params.searchStatus = 'data';
      } else {
        this.params.searchStatus = 'noData';
        // this.$emit('getSearchParams', 'noData')
      }
      this.$emit('getSearchParams', this.params);
    },
    inputChange() {
      // console.log('inputChange', this.inputVal);
      if (this.params.inputVal.trim()) {
        this.params.searchStatus = 'loading';
        // this.$emit('getSearchStatus', 'loading')
        this.$emit('getSearchParams', this.params);
        this.debounceMethod();
      } else {
        // this.$emit('getSearchStatus', '')
        this.params.searchStatus = '';
        this.params.resultList = [];
        this.$emit('getSearchParams', this.params);
      }
    },

    debounceMethod: _.debounce(function () {
      // this.$emit('getSearchParams', this.params);
      if (this.params.inputVal.trim()) {
        this.onSearch();
      }
    }, 1000),
    // onFocus() {
    //   this.$emit('getSearchParams', this.params);
    // },
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  width: 4.4rem;
  display: flex;
  align-items: center;
  border: 0.01rem solid var(--search-border);
  border-radius: 0.16rem;
  padding: 0.1rem 0.12rem;
  &.shadow {
    box-shadow: none;
  }
  .search-icon {
    width: 0.28rem;
    height: 0.28rem;
  }
  .input-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 0.12rem;
    flex: 1;
    input {
      flex: 1;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC-Medium;
      color: var(--gray-1);
      border: none;
      outline: none;
      background: none;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: var(--input-placeholder);
      font-size: 0.14rem;
    }

    input:-moz-placeholder,
    textarea:-moz-placeholder {
      color: var(--input-placeholder);
      font-size: 0.14rem;
    }

    input::-moz-placeholder,
    textarea::-moz-placeholder {
      color: var(--input-placeholder);
      font-size: 0.14rem;
    }
    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
      color: var(--input-placeholder);
      font-size: 0.14rem;
    }

    .delete {
      margin-left: 0.08rem;
      width: 0.14rem;
      height: 0.14rem;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 767px) {
  .search-input {
    width: 100%;
    border-radius: 0.32rem;
    padding: 0.24rem;
    &.shadow {
      box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.08);
    }
    .search-icon {
      width: 0.48rem;
      height: 0.48rem;
    }
    .input-wrap {
      input {
        font-size: 0.26rem;
      }
      input::-webkit-input-placeholder,
      textarea::-webkit-input-placeholder {
        color: var(--input-placeholder);
        font-size: 0.26rem;
      }

      input:-moz-placeholder,
      textarea:-moz-placeholder {
        color: var(--input-placeholder);
        font-size: 0.26rem;
      }

      input::-moz-placeholder,
      textarea::-moz-placeholder {
        color: var(--input-placeholder);
        font-size: 0.26rem;
      }
      input:-ms-input-placeholder,
      textarea:-ms-input-placeholder {
        color: var(--input-placeholder);
        font-size: 0.26rem;
      }
      .delete {
        margin-left: 0.1rem;
        width: 0.28rem;
        height: 0.28rem;
      }
    }
  }
}
</style>
