.
<template>
  <div class="Token"></div>
</template>

<script>
import _ from 'lodash';
export default {
  created() {
    let query = location.search.slice(1)
    let queryObj = {}
    if (query) {
      _.forEach(query.split('&'), (item) => {
        let tempArr = item.split('=')
        queryObj[tempArr[0]] = tempArr[1]
      })
    }
    // console.log('token-queryObj', queryObj);
    let token_id = ''
    let utc_type = ''
    if (queryObj && queryObj.token_id) {
      token_id = queryObj.token_id
      utc_type = queryObj.utc_type || 'UTC+24'
    } else {
      let index = location.hash.indexOf('token/');
      token_id = location.hash.slice(index + 6);
    }
    this.$router.replace({
      name: 'TokenInfo',
      params: { tokenId: token_id, utc_type: utc_type },
    });
  },
};
</script>

<style></style>
