import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(['isDark']),
    darkModeImg() {
      let str = '';
      if (this.isDark) {
        str = '-dark';
      }
      return str;
    },
  },
};
