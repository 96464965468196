let TP_SERVER_DOMAIN, TP_FORWARD_DOMAIN, TP_RUNE_DOMAIN;

if (process.env.VUE_APP_ENV === 'production') {
    TP_SERVER_DOMAIN = 'https://preserver.mytokenpocket.vip';
    TP_FORWARD_DOMAIN = 'https://forward.mytokenpocket.vip';
    TP_RUNE_DOMAIN = 'https://ord.mytokenpocket.vip';
  }
  else if (process.env.VUE_APP_ENV === 'beta') {
    TP_SERVER_DOMAIN = 'https://betaserver.mytokenpocket.vip';
    TP_FORWARD_DOMAIN = 'https://forward.mytokenpocket.vip';
    TP_RUNE_DOMAIN = 'https://ord.mytokenpocket.vip';
  }
  else if (process.env.VUE_APP_ENV === 'dev') {
    TP_SERVER_DOMAIN = 'https://testserver.mytokenpocket.vip';
    TP_FORWARD_DOMAIN = 'https://forwardtest.mytokenpocket.vip';
    TP_RUNE_DOMAIN = 'https://testord.mytokenpocket.vip';
}

export { TP_SERVER_DOMAIN, TP_FORWARD_DOMAIN, TP_RUNE_DOMAIN }