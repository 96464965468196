import { TP_SERVER_DOMAIN } from './config';
import Big from 'big.js';
// 设置全局舍入模式为向下舍入
Big.RM = 0

export const NodeMap = {
  1: 'https://web3.mytokenpocket.vip', //eth
  12: 'https://bsc-dataseed4.ninicoin.io', // bsc
  15: 'https://http-mainnet.hecochain.com', // heco
  18: 'https://matic-mainnet.chainstacklabs.com', // Polygon
  20: 'https://okchain.mytokenpocket.vip', // okex
  23: 'https://api.avax.network/ext/bc/C/rpc', // Avalanche
  24: 'https://arb1.arbitrum.io/rpc', // Arbitrum
  25: 'https://rpcapi.fantom.network', //Fantom
  33: 'https://api.harmony.one', //Harmony
};

export const blockchainBrowserMap = {
  1: 'https://etherscan.io/token/',
  2: 'https://state.jingtum.com/#/wallet/', // 井通
  3: 'http://explorer.moac.io/token/', // 墨客
  4: 'https://bloks.io/account/', // EOS
  // 5: 'https://enumivo.qsx.io/', // ENU
  6: 'https://bos.eosx.io/account/', // bos
  7: 'https://explorer.iost.io/account/', // iost
  8: 'https://cosmos.bigdipper.live/account/', // cosmos
  9: 'https://explorer.binance.org/address/', // binance
  10: 'https://tronscan.org/#/token20/', // tron
  11: 'https://www.blockchain.com/btc/address/', // btc
  12: 'https://bscscan.com/token/',
  // 13: 'https://polkadot.subscan.io/', // polkadot
  14: 'https://kusama.subscan.io/', // kusama
  15: 'https://hecoinfo.com/token/', // heco
  // 16: 'https://www.oklink.com/okexchain/address/', // ok-old
  // 17: 'https://www.oklink.com/okexchain-test/address/', // ok-test
  18: 'https://polygonscan.com/token/', // matic
  19: 'https://hooscan.com/address/', // hoo smart
  20: 'https://www.oklink.com/en/okc/token/',
  21: 'https://subgamescan.io/#/extrinsicsdetail?address=', // subgame
  22: 'https://scope.klaytn.com/token/', // klaytn
  23: 'https://snowtrace.io/token/', // avax
  24: 'https://arbiscan.io/token/', // arb
  25: 'https://ftmscan.com/token/', // ftm
  26: 'https://optimistic.etherscan.io/token/', //op
  27: 'https://solscan.io/token/', // solana
  28: 'https://gnosisscan.io/token/', // gnosis
  29: 'https://wax.bloks.io/account/', // WAX
  31: 'https://moonscan.io/token/', // moon
  32: 'https://explorer.mainnet.aurora.dev/token/', // aurora
  33: 'https://explorer.harmony.one/address/', // harmony
  34: 'https://escan.live/token/', //  evmos
  35: 'https://explorer.kcc.io/cn/token/', // KCC
  36: 'https://evm.confluxscan.net/token/', //  conflux eSpace
  37: 'https://scan.platon.network/tokens-detail?address=', //  platon
  38: 'https://scan.bt.io/#/token20/', // BTTC
  39: 'https://gatescan.org/token/', // GT
  40: 'https://browser.halo.land/token/', // HALO
  41: 'https://etcblockexplorer.com/address/', // ETC
  42: 'https://nova-explorer.arbitrum.io/token/', // Nova
  43: 'https://explorer.aptoslabs.com/account/', // aptos
  // 44: 'https://dogechain.info/', // dog
  45: 'https://filfox.info/en/address/', // fil
  46: 'https://explorer.zksync.io/address/', // zkSync
  47: 'https://explorer.evm.eosnetwork.com/token/', // EOS EVM
};
export const blockchainBrowserAddressMap = {
  1: 'https://etherscan.io/address/',
  2: 'https://state.jingtum.com/#/wallet/', // 井通
  3: 'http://explorer.moac.io/addr/', // 墨客
  4: 'https://bloks.io/account/', // EOS
  // 5: 'https://enumivo.qsx.io/', // ENU
  6: 'https://bos.eosx.io/account/', // bos
  7: 'https://www.iostabc.com/account/', // iost
  8: 'https://cosmos.bigdipper.live/account/', // cosmos
  9: 'https://explorer.binance.org/address/', // binance
  10: 'https://tronscan.org/#/address/', // tron
  11: 'https://www.blockchain.com/btc/address/', // btc
  12: 'https://bscscan.com/address/',
  // 13: 'https://polkadot.subscan.io/', // polkadot
  14: 'https://kusama.subscan.io/account/', // kusama
  15: 'https://hecoinfo.com/address/', // heco
  // 16: 'https://www.oklink.com/okexchain/address/', // ok-old
  // 17: 'https://www.oklink.com/okexchain-test/address/', // ok-test
  18: 'https://polygonscan.com/address/', // matic
  19: 'https://hooscan.com/address/', // hoo smart
  20: 'https://www.oklink.com/en/oktc/address/',
  21: 'https://subgamescan.io/#/extrinsicsdetail?address=', // subgame
  22: 'https://scope.klaytn.com/account/', // klaytn
  23: 'https://snowtrace.io/address/', // avax
  24: 'https://arbiscan.io/address/', // arb
  25: 'https://ftmscan.com/address/', // ftm
  26: 'https://optimistic.etherscan.io/address/', //op
  27: 'https://solscan.io/account/', // solana
  28: 'https://gnosisscan.io/address/', // gnosis
  29: 'https://wax.bloks.io/account/', // WAX
  31: 'https://moonscan.io/address/', // moon
  32: 'https://explorer.mainnet.aurora.dev/address/', // aurora
  33: 'https://explorer.harmony.one/address/', // harmony
  34: 'https://escan.live/address/', //  evmos
  35: 'https://explorer.kcc.io/cn/address/', // KCC
  36: 'https://evm.confluxscan.net/address/', //  conflux eSpace
  37: 'https://scan.platon.network/address-detail?address=', //  platon
  38: 'https://scan.bt.io/#/address/', // BTTC
  39: 'https://gatescan.org/address/', // GT
  40: 'https://browser.halo.land/address/', // HALO
  41: 'https://etcblockexplorer.com/address/', // ETC
  42: 'https://nova-explorer.arbitrum.io/address/', // Nova
  43: 'https://explorer.aptoslabs.com/account/', // aptos
  // 44: 'https://dogechain.info/', // dog
  45: 'https://filfox.info/en/address/', // fil
  46: 'https://explorer.zksync.io/address/', // zkSync
  47: 'https://explorer.evm.eosnetwork.com/address/', // EOS EVM
};

/** 设置显示模式 */
export function setMode(mode) {
  if (mode) {
    document.documentElement.setAttribute('theme', 'dark');
  } else {
    document.documentElement.removeAttribute('theme');
  }
}

export const tpBlockchainIdMap = {
  1: '1',
  10: 'tron',
  12: '56',
  15: '128',
  18: '137',
  20: '65',
  23: '43114',
  24: '42161',
  25: '250',
  26: '10',
  28: '100',
  33: '1666600000',
  35: '321',
};

export const holeAddressList = [
  '0x0000000000000000000000000000000000000000',
  '0x0000000000000000000000000000000000000001',
  '0x0000000000000000000000000000000000000002',
  '0x0000000000000000000000000000000000000003',
  '0x0000000000000000000000000000000000000004',
  '0x0000000000000000000000000000000000000005',
  '0x0000000000000000000000000000000000000006',
  '0x0000000000000000000000000000000000000007',
  '0x0000000000000000000000000000000000000008',
  '0x0000000000000000000000000000000000000009',
  '0x000000000000000000000000000000000000000a',
  '0x000000000000000000000000000000000000000b',
  '0x000000000000000000000000000000000000000c',
  '0x000000000000000000000000000000000000000d',
  '0x000000000000000000000000000000000000000e',
  '0x000000000000000000000000000000000000dead',
];

/** 格式化代币价格 */
export function parsePrice(val) {
  try {
    if (String(val).indexOf('e') !== -1) {
      const str = String(val);
      const index = str.indexOf('e');
      if (str.charAt(index + 1) !== '-') {
        return val;
      }
      const zeroCount = Number(str.substring(index + 2)) - 1;
      const efficientNumbers = str
        .substring(0, index)
        .split('.')
        .join('')
        .substring(0, 3);
      return `0.0{${zeroCount}}${efficientNumbers}`;
    } else if (val >= 10) {
      return val.toFixed(2);
    } else if (val < 10 && val >= 1) {
      return val.toFixed(3);
    } else if (val > 0) {
      const arr = val.toString().split('');
      const pointIndex = arr.indexOf('.');
      if (pointIndex >= 0) {
        let firstEfficientIndex = pointIndex;
        for (let i = pointIndex + 1; i < arr.length; i++) {
          if (arr[i] !== '0') {
            firstEfficientIndex = i;
            break;
          }
        }
        if (val < 1 && val >= 0.0001) {
          return arr
            .slice(0, Math.min(firstEfficientIndex + 3, arr.length))
            .join('');
        } else {
          return `0.0{${firstEfficientIndex - pointIndex - 1}}${arr
            .slice(
              firstEfficientIndex,
              Math.min(firstEfficientIndex + 3, arr.length)
            )
            .join('')}`;
        }
      }
    } else {
      return val;
    }
  } catch (err) {
    console.log(err, val);
  }
}

/** 格式化大额数字 */
export function parseWithUnit(val, type) {
  const scientificNumber = val; // 科学计数法表示的数
  const bigNumber = new Big(scientificNumber);
  const normalNumberString = bigNumber.toFixed(); // 将科学计数法转换为普通数
  val = Number(normalNumberString);
  try {
    if (val >= 1e30) {
      return Math.round(val / 1e27) / 1000 + 'Q';
    } else if (val < 1e30 && val >= 1e27) {
      return Math.round(val / 1e24) / 1000 + 'R';
    } else if (val < 1e27 && val >= 1e24) {
      return Math.round(val / 1e21) / 1000 + 'Y';
    } else if (val < 1e24 && val >= 1e21) {
      return Math.round(val / 1e18) / 1000 + 'Z';
    } else if (val < 1e21 && val >= 1e18) {
      return Math.round(val / 1e15) / 1000 + 'E';
    } else if (val < 1e18 && val >= 1e15) {
      return Math.round(val / 1e12) / 1000 + 'P';
    } else if (val < 1e15 && val >= 1e12) {
      return Math.round(val / 1e9) / 1000 + 'T';
    } else if (val < 1e12 && val >= 1e9) {
      return Math.round(val / 1e6) / 1000 + 'B';
    } else if (val < 1e9 && val >= 1e6) {
      return Math.round(val / 10000) / 100 + 'M';
    } else if (val < 1e6 && val >= 10000) {
      return Math.round(val / 10) / 100 + 'K';
    } else if (val < 10000 && val >= 1) {
      if (type === 'count') {
        return val;
      } else {
        return val.toFixed(1);
      }
    } else if (val > 0) {
      if (type === 'tvl') {
        return 0;
      } else {
        return parsePrice(val);
      }
    } else {
      return 0;
    }
  } catch (err) {
    console.log(err, val);
  }
}

/** 保留小数位不四舍五入 */
export function truncateDecimals(num, digits) {
  try {
    const scientificNumber = num; // 科学计数法表示的数
    const bigNumber = new Big(scientificNumber);
    const normalNumberString = bigNumber.round(digits); // 保留2为小数
    return normalNumberString.toNumber()
  } catch (error) {
    console.log(error);
  }
}

export const TRANSIT_NS_CHAIN_ID_MAP = {
  'ethereum:1': 'ETH',
  'eosio:aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906':
    'EOS',
  'iost:1024': 'IOST',
  'tron:728126428': 'TRX',
  'bitcoin:000000000019d6689c085ae165831e934ff763ae46a2a6c172b3f1b60a8ce26f':
    'BTC',
  'ethereum:56': 'BSC',
  'polkadot:0': 'DOT',
  'polkadot:2': 'KSM',
  'ethereum:128': 'HECO',
  'ethereum:137': 'MATIC',
  'ethereum:66': 'OKTC',
  'klaytn:8217': 'KLAY',
  'ethereum:43114': 'AVAX',
  'ethereum:42161': 'ARB',
  'ethereum:250': 'FTM',
  'ethereum:10': 'OP',
  'solana:1': 'SOL',
  'ethereum:1284': 'GLMR',
  'ethereum:321': 'KCC',
  'ethereum:1030': 'CFX',
  'ethereum:61': 'ETC',
  'aptos:25': 'APTOS',
  'bitcoin:1a91e3dace36e2be3bf030a65679fe821aa1d6ef92e7c9902eb318182c355691':
    'DOGE',
  'ethereum:324': 'zkSync Era',
  'ethereum:17777': 'EOS EVM',
  'ethereum:5000': 'Mantle',
  'ethereum:59144': 'LINEA',
  'ethereum:8453': 'Base',
  'ethereum:204': 'OPBNB',
};

export const BRC20_TRADING_MAP = {
  'inscribe-deploy': 'Deploy',
  'inscribe-mint': 'Mint',
  'inscribe-transfer': 'Receive',
  transfer: 'Send',
};

export async function tokenSearch(address, lang) {
  try {
    const response = await fetch(
      `${TP_SERVER_DOMAIN}/v1/token/detail_search?address=${address}&lang=${lang}`
    );
    const res = await response.json();
    if (res.result === 0 && res.data) {
      res.data.forEach((item) => {
        item.isSelected = false;
      });
      return res.data;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}
