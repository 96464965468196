import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _ from 'lodash'
import zh from './lang/zh'
import en from './lang/en'

Vue.use(VueI18n)

let availableLocale = ['zh-CN', 'zh', 'en']

let defaultLangStr = navigator.language
let defaultLang = 'en'

if (defaultLangStr.indexOf('zh') >= 0) {
  defaultLang = 'zh'
}
let query = location.search.substr(1)
let queryObj = {}

if (query) {
  _.forEach(query.split('&'), (item) => {
    let tempArr = item.split('=')
    queryObj[tempArr[0]] = tempArr[1]
  })
}

let locale = _.includes(availableLocale, queryObj['locale'])
  ? queryObj['locale']
  : defaultLang

if (locale === 'zh-CN') {
  locale = 'zh'
}

locale = localStorage.getItem('locale') || locale

// 兼容 query 传参
// const obj = getQueryObject(location.href)
// if (obj.locale) {
//   locale = obj.locale
// }

/**
 * @param {string} url
 * @returns {Object}
 */
function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

let i18n = new VueI18n({
  locale,
  messages: {
    en: en,
    zh: zh,
  },
})

export default i18n
