<template>
  <div class="home-view">
    <Header />
    <div class="home-view-content">
      <img class="home-img" :src="homeImg" alt="" />
      <div class="search-wrap">
        <SearchInput @getSearchParams="getSearchParams" />
        <div
          class="search-result-content"
          :class="{ visibility: searchStatus !== '' || resultList.length }"
        >
          <div class="search-loading" v-if="searchStatus === 'loading'">
            <img :src="loadingImg" alt="" />
          </div>
          <div class="no-search-result" v-else-if="searchStatus === 'noData'">
            <img :src="noDataImg" alt="" />
            <span>{{ $t('no_data') }}</span>
          </div>
          <div class="search-result-list" v-else>
            <TokenItem
              v-for="(item, index) in resultList"
              :key="index"
              :token="item"
            />
          </div>
        </div>
      </div>
      <div
        class="button"
        :class="{ dark: isDark, active: inputVal !== '' }"
        @click="onSearch"
      >
        {{ $t('submit') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import darkModeImg from '../mixins/darkModeImg';
import Header from '../components/layout/Header.vue';
import SearchInput from '../components/common/SearchInput.vue';
import TokenItem from '../components/common/TokenItem.vue';
import { tokenSearch } from '../utils';

export default {
  name: 'Home',
  components: { Header, SearchInput, TokenItem },
  mixins: [darkModeImg],
  data() {
    return {
      inputVal: '',
      resultList: [],
      searchStatus: '',
    };
  },
  computed: {
    ...mapGetters(['isDark']),
    homeImg() {
      return require(`../assets/home/home-img${this.darkModeImg}.png`);
    },
    loadingImg() {
      return require(`../assets/loading${this.darkModeImg}.png`);
    },
    selectedImg() {
      return require(`../assets/home/selected${this.darkModeImg}.png`);
    },
    searchImg() {
      return require(`../assets/search${this.darkModeImg}.png`);
    },
    noDataImg() {
      return require(`../assets/home/no-data${this.darkModeImg}.png`);
    },
  },
  created() {},

  methods: {
    getSearchParams(params) {
      // console.log('params', params);
      const { inputVal, resultList, searchStatus } = params;
      this.inputVal = inputVal;
      this.resultList = resultList;
      this.searchStatus = searchStatus;
    },
    async onSearch() {
      let lang = 'en'
      if (this.$i18n.locale === 'zh') {
        lang = 'zh-Hans'
      }
      this.searchLoading = true;
      const data = await tokenSearch(this.inputVal, lang);
      // console.log('data', data);
      // this.resultList = [...data, ...data,...data,...data,...data,];
      this.resultList = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-view {
  position: relative;
  background: var(--bg-1);
  min-height: 100vh;
  .home-view-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-90%);
    .home-img {
      margin-top: 1rem;
      width: 2.99rem;
      height: 2.1rem;
    }
    .search-wrap {
      position: relative;
      margin-top: 0.32rem;
      .search-result-content {
        position: absolute;
        z-index: 10;
        top: 0.6rem;
        left: 50%;
        transform: translateX(-50%);
        width: 4.4rem;
        background: var(--search-result-bg);
        border: 0.01rem solid var(--search-result-border);
        border-radius: 0.16rem;
        box-shadow: 0 0.02rem 0.2rem 0 rgba(0, 0, 0, 0.15);
        padding: 0.16rem 0.1rem 0.24rem;
        visibility: hidden;
        &.visibility {
          visibility: visible;
        }
        .search-result-list {
          max-height: 3.44rem;
          overflow: auto;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
          .search-result-item {
            .network {
              font-size: 0.14rem;
              font-family: PingFang SC, PingFang SC-Medium;
              color: var(--gray-15);
              padding: 0 0.06rem;
            }
            .token-info {
              margin-top: 0.13rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0.08rem 0.06rem;
              cursor: pointer;
              .token-info-left {
                display: flex;
                align-items: center;
                .token-logo-wrap {
                  position: relative;
                  display: flex;
                  .symbol-logo {
                    width: 0.32rem;
                    height: 0.32rem;
                  }
                  .network-logo {
                    position: absolute;
                    right: -0.02rem;
                    bottom: -0.02rem;
                    border: 0.02rem solid #fff;
                    width: 0.12rem;
                    height: 0.12rem;
                    border-radius: 50%;
                  }
                }
                .token-detail {
                  margin-left: 0.12rem;
                  .symbol {
                    font-size: 0.13rem;
                    font-family: PingFang SC, PingFang SC-Medium;
                    color: var(--gray-15);
                  }
                  .address {
                    margin-top: 0.02rem;
                    font-size: 0.12rem;
                    font-family: PingFang SC, PingFang SC-Medium;
                    color: var(--gray-11);
                    transform: scale(0.83);
                    margin-left: -0.02rem;
                  }
                  .address-pc {
                    display: block;
                  }
                  .address-mobile {
                    display: none;
                  }
                }
              }
              .token-info-right {
                display: flex;
                img {
                  width: 0.16rem;
                  height: 0.16rem;
                }
              }
            }
            .token-info:hover {
              background: var(--search-result-token-info-hover);
              border-radius: 0.12rem;
            }
          }
        }
        .search-loading {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0.5rem 0 0.42rem;
          img {
            width: 0.4rem;
            height: 0.4rem;
            animation: rotate 2s linear infinite;
          }
        }
        .no-search-result {
          margin: 0.5rem 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 0.64rem;
            height: 0.64rem;
          }
          span {
            margin-top: 0.12rem;
            font-size: 0.12rem;
            font-family: PingFang SC, PingFang SC-Medium;
            color: var(--gray-11);
          }
        }
      }
    }
    .button {
      display: none;
    }
  }
}
@media screen and (max-width: 1400px) {
  .home-view {
    .home-view-content {
      .button {
        display: none;
        width: 4.4rem;
        margin-top: 0.16rem;
        background: #a1c8ff;
        border-radius: 0.16rem;
        padding: 0.16rem 0;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC-Medium;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        &.dark {
          opacity: 0.2;
        }
        &.active {
          background: #2980fe;
          opacity: 1;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .home-view {
    padding: 0 0.32rem;
    .home-view-content {
      width: calc(100% - 0.64rem);
      .home-img {
        width: 4.1rem;
        height: 2.88rem;
      }
      .search-wrap {
        margin-top: 0.8rem;
        width: 100%;
        .search-result-content {
          width: 100%;
          top: 1.1rem;
          border-radius: 0.32rem;
          padding: 0.32rem 0.32rem 0.6rem;
          .search-result-list {
            max-height: 5rem;
            .search-result-item {
              .network {
                font-size: 0.26rem;
                padding: 0;
              }
              .token-info {
                padding: 0;
                margin-top: 0.36rem;
                .token-info-left {
                  .token-logo-wrap {
                    .symbol-logo {
                      width: 0.72rem;
                      height: 0.72rem;
                    }
                    .network-logo {
                      width: 0.28rem;
                      height: 0.28rem;
                    }
                  }
                  .token-detail {
                    margin-left: 0.28rem;
                    .symbol {
                      font-size: 0.26rem;
                    }
                    .address {
                      font-size: 0.2rem;
                      transform: scale(1);
                      margin-left: 0;
                    }
                    .address-pc {
                      display: none;
                    }
                    .address-mobile {
                      display: block;
                    }
                  }
                }
                .token-info-right {
                  img {
                    width: 0.3rem;
                    height: 0.3rem;
                  }
                }
              }
              .token-info:hover {
                background: none;
              }
            }
          }
          .search-loading {
            margin: 0.5rem 0 0.24rem;
            img {
              width: 0.6rem;
              height: 0.6rem;
            }
          }
          .no-search-result {
            img {
              width: 1.08rem;
              height: 1.08rem;
            }
            span {
              margin-top: 0.24rem;
              font-size: 0.24rem;
            }
          }
        }
      }
      .button {
        width: 100%;
        margin-top: 0.44rem;
        background: #a1c8ff;
        border-radius: 0.32rem;
        padding: 0.3rem 0;
        font-size: 0.26rem;
        &.dark {
          opacity: 0.2;
        }
        &.active {
          background: #2980fe;
          opacity: 1;
        }
      }
    }
  }
}
</style>
