<template>
  <div class="header">
    <div class="header-left" @click="goHome">
      <img :src="logoImg" alt="" />
    </div>
    <div class="header-right">
      <div class="mode-wrap" @click="changeMode">
        <img :src="modeImg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import darkModeImg from '../../mixins/darkModeImg';
import { setMode } from "../../utils";

export default {
  name: 'Header',
  mixins: [darkModeImg],
  computed: {
    logoImg() {
      return require(`../../assets/home/TP-logo${this.darkModeImg}.png`);
    },
    modeImg() {
      return require(`../../assets/home/mode${this.darkModeImg}.png`);
    },
  },
  methods: {
    changeMode() {
      let mode = document.documentElement.hasAttribute('theme');
      // console.log('mode', mode)
      setMode(!mode);
      this.$store.commit('setDark', !mode);
    },
    goHome() {
      // console.log('goHome');
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  max-width: 12rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.1rem 0;
  .header-left {
    display: flex;
    align-items: center;
    img {
      width: 1.68rem;
      height: 0.24rem;
      cursor: pointer;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    .mode-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.3rem;
      height: 0.3rem;
      background: var(--mode-bg);
      border-radius: 50%;
      cursor: pointer;
      img {
        width: 0.18rem;
        height: 0.18rem;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .header {
    padding: 0.1rem 0.3rem;
  }
}

@media screen and (max-width: 767px) {
  .header {
    padding: 0.4rem 0;
    .header-left {
      img {
        width: 2.53rem;
        height: 0.36rem;
      }
    }
    .header-right {
      .mode-wrap {
        width: 0.44rem;
        height: 0.44rem;
        img {
          width: 0.25rem;
          height: 0.25rem;
        }
      }
    }
  }
}
</style>
