import Vue from 'vue';
import Vuex from 'vuex';
import { TP_SERVER_DOMAIN } from '../utils/config';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isDark: false,
    isMobileSize: false,
    tokenId: undefined,
    isTon: false,
    blockchainList_ZH: [],
    blockchainList_EN: [],
  },
  mutations: {
    setDark(state, payload) {
      state.isDark = payload;
    },
    setMobileSize(state, payload) {
      state.isMobileSize = payload;
    },
    setTokenId(state, payload) {
      state.tokenId = payload;
    },
    setTon(state, payload) {
      state.isTon = payload;
    },
    setBlockchainList_ZH(state, payload) {
      state.blockchainList_ZH = payload;
    },
    setBlockchainList_EN(state, payload) {
      state.blockchainList_EN = payload;
    },
  },
  actions: {
    async SET_BLOCK_CHAIN_LIST({ commit }, payload) {
      const { lang, os } = payload;
      let setStr = 'blockchainList-en';
      try {
        const response = await fetch(
          `${TP_SERVER_DOMAIN}/v1/block_chain/list?lang=${lang}&version=0.0.1&os=${os}`
        );
        const res = await response.json();
        if (res.result === 0 && res.data) {
          let list = res.data.map((item) => {
            const { hid, icon_url, title } = item
            return { hid: hid, icon: icon_url, title: title };
          });
          if (lang === 'zh-Hans') {
            setStr = 'blockchainList-zh';
            commit('setBlockchainList_ZH', list);
          } else {
            commit('setBlockchainList_EN', list);
          }
          localStorage.setItem(setStr, JSON.stringify(list));
        }
      } catch (error) {
        // todo 如果获取不到 本地也没有 是否需要处理  blockchain
        if (lang === 'zh-Hans') {
          setStr = 'blockchainList-zh';
          let list_ZH = JSON.parse(localStorage.getItem(setStr)) || [];
          commit('setBlockchainList_ZH', list_ZH);
        } else {
          let list_EN = JSON.parse(localStorage.getItem(setStr)) || [];
          commit('setBlockchainList_EN', list_EN);
        }
        console.log(error);
      }
    },
  },
  getters: {
    isDark(state) {
      return state.isDark;
    },
    isMobileSize(state) {
      return state.isMobileSize;
    },
    tokenId(state) {
      return state.tokenId;
    },
    isTon(state) {
      return state.isTon;
    },
    blockchainList_ZH(state) {
      return state.blockchainList_ZH;
    },
    blockchainList_EN(state) {
      return state.blockchainList_EN;
    },
  },
  modules: {},
});
