import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Token from '../views/Token.vue';
// import TokenInfo from '../views/TokenInfo.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/token/:id',
    name: 'Token',
    component: Token,
  },
  {
    path: '/token-info',
    name: 'TokenInfo',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/TokenInfo.vue'),
  },
  // {
  //   path: '/tokenDetection',
  //   name: 'tokenDetection',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/TokenDetection.vue'),
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
